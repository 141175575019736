.pt_40{
  padding-top:40px;
}
.d_flex{
  display:flex!important;
}
.align_center{
  align-items: center;
}
.justify_center{
  justify-content:center;
}
.login-image {
	background-image: radial-gradient(#e9f5f5,#e9f5f5);
  height: 100%;
}
.Polaris-TopBar-Menu__Activator {
  padding: 1.1rem 0.8rem !important;
}

.vendor-page {
  display: flex;
}

.vendor-page--side-nav {
  margin-right: 10px;
  transition: min-width 0.5s linear;
}

.vendor-page--main {
  flex-basis: 100%;
  /* overflow-x: scroll; */
}

.input_cursor .Polaris-TextField__Input {
  cursor: pointer;
}
.input_cursor input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.vendor-page--icon {
  position: absolute;
  left: 200px;
  padding: 10px 0;
}

.vendor-page--nav-wrap {
  position: relative;
  flex-basis: 250px;
  min-height: 200px;
}

.hide.vendor-page--side-nav {
  width: 0;
}

.Polaris-Select__Input {
  cursor: pointer;
}

.dashboardpiechart,
.dashboardpiechart .Polaris-Card {
  height: 100% !important;
}
.dashboardpiechartcont .Polaris-Layout {
  align-items: unset !important;
}

@media only screen and (max-width: 767px) {
  .vendor-page {
    flex-wrap: wrap;
  }
  .vendor-page--side-nav {
    flex: 100%;
    width: 100%;
    margin-right: 0;
  }
  .vendor-page--nav-wrap {
    width: 100%;
    flex-basis: 100%;
  }
  .vendor-page--main {
    flex: 100%;
    margin-top: 10px;
  }
  .vendor-page--icon {
    display: none;
  }
}

.notification_center .Polaris-ResourceItem__Container {
  align-items: center !important;
}
