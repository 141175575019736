/******************************  Card Css   **********************************/
.CARD {
    position:relative;
    margin-top:5%;
    border-radius:5px;
    box-shadow: 0 4px 10px -5px #777777;
    border: 1px solid #cccccc;
}
.CARD-title-small {
    position:absolute;
    width:30%;
    min-height: 80px;
    padding: 10px 10px 10px;
    max-height: 100px;
    top:-50px;
    overflow: auto;
    left:5%;
    background-color: #9c27b0;
    color: #fff;
    border-radius:5px;
    box-shadow: 0 7px 10px -5px #777777;
}
.CARD:hover .CARD-title-small {
    top:-70px;
}
.CARD:hover .CARD-title {
    top:-70px;
}
.CARD-title {
    position:absolute;
    color: #fff;
    width:80%;
    min-height: 80px;
    padding: 10px 10px 10px;
    max-height: 100px;
    top:-50px;
    overflow: auto;
    left:10%;
    background-color:#00acc1;
    border-radius:5px;
    box-shadow: 0 7px 10px -5px #777777;
}
.BG-primary {
    background: linear-gradient(60deg, #ab47bc, #8e24aa) !important;
}
.BG-info {
    background: linear-gradient(60deg, #3adaee, #00d3ee) !important;
}
.BG-success {
    background: linear-gradient(60deg, #68ee6a, #00ee08) !important;
}
.BG-danger {
    background: linear-gradient(60deg, #ef595b, #e53935) !important;
}
.BG-warn {
    background: linear-gradient(60deg, #ffbc54, #ffa21a) !important;
}
.BG-dark {
    background: linear-gradient(60deg, #403d3e, #000000) !important;
    color: #fff;
}
.BG-light {
    background: linear-gradient(60deg, #ffffff, #b4afb0) !important;
    color: #202020;
}
.CARD-body {
    margin-top:25px;
    font-family: Roboto,Helvetica,Arial,sans-serif;
}

/******** Facebook-form  *********/

.facebookChoice {
    border-radius: 10px;
    border: 2px solid #919eab61;
    padding: 20px;
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.facebookChoice:hover {
    cursor: pointer;
    background-color: #c9eaff;
}

.facebookChoice:active {
    cursor: pointer;
    background-color: rgba(183, 181, 255, 0.6);
}

.facebookCardBox {
    margin-left : 30%;
    margin-right : 30%
}

@media screen and (max-width: 650px) {
    .facebookChoice {
        width: 100% !important;
    }
    .facebookCardBox {
        margin-left : 0;
        margin-right : 0
    }
}

/*
Mobile View
 */
@media screen and (min-width: 650px) {
    .facebookChoice {
        width: 100% !important;
    }
}